/* eslint-disable */

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Map, TileLayer, LayersControl, Polygon, LayerGroup, Marker, Tooltip as LeafletTooltip } from "react-leaflet";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import Choropleth from "react-leaflet-choropleth";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import { format } from 'date-fns';
import { gradient } from "./gradient";
// import boats from "../../dummy-data/boats";
import boats from "../../dummy-data/boats2";
import { orange, yellow, green, red, blue} from "@material-ui/core/colors";

import Chart from "chart.js/auto";
import "chartjs-adapter-moment";
import graphsData from "../../dummy-data/carnival_report.json";
import useInterval from "../../utils/useInterval";
import AnimationControls from "../../components/AnimationControls";
import FilterForm from "../../components/FilterForm";
import MapControls from "../../components/MapControls";
import { Line } from 'react-chartjs-2';
import boatIcon from "../../components/BoatIcon";
import  satellites from "../../dummy-data/satellites";

const style = {
  fillColor: "#F28F3B",
  weight: 0.5,
  opacity: 0.5,
  color: "white",
  dashArray: "3",
  fillOpacity: 0.5,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  }
}));


// Proxy to local back-end server
function fetchApi(resource, init) {
  return fetch(`${process.env.REACT_APP_ENDPOINT}/${resource}`, init);
}

function MaritimeScreen() {
  
  const [data, setData] = useState([]);
  const [map, setMap] = useState(null);

  // Animation
  const firstFrameIndex = 1;
  const [currentFrameIndex, setCurrentFrameIndex] = useState(firstFrameIndex);
  const [isRunning, setIsRunning] = useState(false);
  const [speedBoat, setSpeedBoat] = useState(100);

  const currentBoatsInfos =  useMemo(() => boats.map(b => ({
    ...b.positions[currentFrameIndex],
    name: b.boatName,
    company: b.company,
  })), [currentFrameIndex]);

  useEffect(() => {
    if(currentFrameIndex === boats[0].positions.length) {
      stopAnimation();
    }
  }, [currentFrameIndex]);

  useInterval(() => {
    setCurrentFrameIndex(currentFrameIndex + 1);
    // addDataToGraphs();
  }, isRunning ? speedBoat : null);

  function stopAnimation(){
    setCurrentFrameIndex(firstFrameIndex);
    // resetDataGraphs();
    setIsRunning(false);
  }

  //Graphs

  const initDateMilliseconds = 1555891200000; // 2019-04-227T00:00:00
  const secondsToDate = (deltaSeconds) => new Date(initDateMilliseconds + deltaSeconds * 1000);

  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);

  const datasetOptions = {
    backgroundColor: "rgba(255, 99, 132, 0.2)",
    borderColor: "rgba(255, 99, 132, 1)",
    borderWidth: 1,
  };
  const chartOptions = {
    animation: {
      duration: 0, // general animation time
    },
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    responsiveAnimationDuration: 0,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
    },
    elements: {
      line: {
          tension: 0, // disables bezier curves
      }
    },
    showLines: false,
    spanGaps: true
  };
  const [chartData1, setChartData1] = useState({
    datasets: [
            {
              label: "Min Provisioning per Ship (Mbps)",
              data: [],
              ...datasetOptions,
            },
          ],
  });
  const [chartData2, setChartData2] = useState({
    datasets: [
            {
              label: "Sailing VS Served",
              data: [],
              ...datasetOptions,
            },
          ],
  });
  const [chartData3, setChartData3] = useState({
    datasets: [
            {
              label: "Total FWD throughput",
              data: [],
              ...datasetOptions,
            },
          ],
  });

  const addDataToGraphs = () => {
    if(graphsData[currentFrameIndex]["time"]){
      chartRef1.current.data.datasets[0].data.push({
        x: secondsToDate(Number(graphsData[currentFrameIndex]["time"])),
            y: graphsData[currentFrameIndex]["provisioning Mbps minimum par bateau"]
      });

      chartRef2.current.data.datasets[0].data.push({
        x: secondsToDate(Number(graphsData[currentFrameIndex]["time"])),
            y: graphsData[currentFrameIndex]["nombre de bateaux en service / navigant"]
      });

      chartRef3.current.data.datasets[0].data.push({
        x: secondsToDate(Number(graphsData[currentFrameIndex]["time"])),
            y: graphsData[currentFrameIndex]["nombre de bateaux en service / navigant"]
      });
    }
  };


  const resetDataGraphs = () => {
    chartRef1.current.data.datasets[0].data = [];
    chartRef2.current.data.datasets[0].data = [];
    chartRef3.current.data.datasets[0].data = [];
  }

  ///////////////////////////////////////////////////////////////

  const classes = useStyles();
  const [openLoadingBar, setOpenLoadingBar] = React.useState(false);

  async function showOnMap(params) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        params
      }),
    };
    setOpenLoadingBar(!openLoadingBar);
    await fetchApi("api/maritime/capacity", requestOptions)
      .then((response) => response.json())
      .then((data) => setData(data))
      .finally(() => {
        setOpenLoadingBar(false);
      });
  }

  async function exportCsv(params) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        params
      }),
    };
    setOpenLoadingBar(!openLoadingBar);
    await fetchApi("api/maritime/export", requestOptions)
      .then(function (t) {
        return t.blob().then((b) => {
          let a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", "export.csv");
          a.click();
        });
      })
      .finally(() => {
        setOpenLoadingBar(false);
      });
  }

  // supply animation 
  const supplyDensityColors = [
    green[200],
    yellow[200],
    orange[200],
    red[200],
  ]

  function getSupplyPolygonColor(beam) {
    const min = 0;
    const max = 3;
    return supplyDensityColors[Math.floor(Math.random() * (max - min + 1) + min)];
  }

  function getSupplyPolygonOpacity(beam, beam_ids = [], st) {
    const opacity =  beam_ids.includes(beam.id) ? 0.5 : 0;
    return opacity;
  }

  return (
    <div>
      <div style={{display: "flex"}}>
        <div style={{width: "70%", margin: "20px"}}>
          <Map center={[48.85, 2.44]} zoom={3} zoomControl={true} whenReady={(event) => setMap(event.target)}>
            <LayersControl>
              <LayersControl.BaseLayer name="Base" checked>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite">
                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community" />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Other">
                <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors' />
              </LayersControl.BaseLayer>
              <LayersControl.Overlay name="Matrix" checked>
                <Choropleth
                  data={data}
                  identity={(feature) => feature.id.toString() + Math.random().toString()}
                  valueProperty={(feature) => feature.properties.density}
                  visible={(feature) => feature.properties.density}
                  scale={[gradient[0], gradient[gradient.length - 1]]}
                  steps={500}
                  mode="e"
                  style={style}
                  onEachFeature={(feature, layer) => layer.bindPopup("Provisioning : " + feature.properties.density.toString() + " Mbps Lat : " + feature.properties.lat.toString() + " Lon : " + feature.properties.lon.toString())}
                />
              </LayersControl.Overlay>
              <LayersControl.Overlay name="HeatMap" checked>
                <HeatmapLayer
                  points={data["features"] || []}
                  longitudeExtractor={(m) => m["properties"]["lon"]}
                  latitudeExtractor={(m) => m["properties"]["lat"]}
                  intensityExtractor={(m) => parseFloat(m["properties"]["density"])}
                />
              </LayersControl.Overlay>
              <LayersControl.Overlay name="KVHTS" checked={currentBoatsInfos[0].satellite_id === "KVHTS"}>
                <LayerGroup>
                  {satellites.KVHTS.map((beam, index) => (
                    <Polygon
                      key={index}
                      stroke={true}
                      opacity={0.5}
                      color="#ba922e"
                      onMouseover={e => e.target.setStyle({ opacity: 1 })}
                      onMouseout={e => e.target.setStyle({ opacity: 0.5 })}
                      fillOpacity={getSupplyPolygonOpacity(beam, currentBoatsInfos[0].beam_ids, currentBoatsInfos[0].satellite_id)}
                      weight={1.2}
                      fillColor={yellow[200]}
                      positions={beam.coordinates.map((c) => {
                        return [c[1], c[0]];
                      })}
                    ><LeafletTooltip sticky>{beam.name}</LeafletTooltip></Polygon>
                  ))}
                </LayerGroup>
              </LayersControl.Overlay>
              <LayersControl.Overlay name="E10B_GSM" checked={currentBoatsInfos[0].satellite_id === "E10B_GSM"}>
                <LayerGroup>
                  {satellites.E10B_GSM.map((beam, index) => (
                    <Polygon
                      key={index}
                      stroke={true}
                      opacity={0.5}
                      onMouseover={e => e.target.setStyle({ opacity: 1 })}
                      onMouseout={e => e.target.setStyle({ opacity: 0.5 })}
                      weight={1.2}
                      fillOpacity={getSupplyPolygonOpacity(beam, currentBoatsInfos[0].beam_ids, currentBoatsInfos[0].satellite_id)}
                      // fillColor={getSupplyPolygonColor(beam)}
                      fillColor={blue[200]}
                      positions={beam.coordinates.map((c) => {
                        return [c[1], c[0]];
                      })}
                    ><LeafletTooltip sticky>{beam.name}</LeafletTooltip></Polygon>
                  ))}
                </LayerGroup>
              </LayersControl.Overlay>
              <LayersControl.Overlay name="E10B_HTS" checked={currentBoatsInfos[0].satellite_id === "E10B_HTS"}>
                <LayerGroup>
                  {satellites.E10B_HTS.map((beam, index) => (
                    <Polygon
                      key={index}
                      stroke={true}
                      opacity={0.5}
                      onMouseover={e => e.target.setStyle({ opacity: 1 })}
                      onMouseout={e => e.target.setStyle({ opacity: 0.5 })}
                      fillOpacity={getSupplyPolygonOpacity(beam, currentBoatsInfos[0].beam_ids, currentBoatsInfos[0].satellite_id)}
                      weight={1.2}
                      // fillColor={getSupplyPolygonColor(beam)}
                      fillColor={blue[200]}
                      positions={beam.coordinates.map((c) => {
                        return [c[1], c[0]];
                      })}
                    ><LeafletTooltip sticky>{beam.name}</LeafletTooltip></Polygon>
                  ))}
                </LayerGroup>
              </LayersControl.Overlay>
              <LayersControl.Overlay name="Boats" checked>
                <LayerGroup>
                { currentBoatsInfos
                  .filter((b) => b.lon && b.lat)
                  .map((boat, index) => { 
                    return <Marker 
                              key={index} 
                              position={[boat.lat, boat.lon]} 
                              icon={boatIcon(boat.name, boat.color, boat.company, boat.needed)}
                            >
                              {boat.satellite_id && 
                                <LeafletTooltip permanent direction="right" offset={[25, -5]}>
                                  <strong>Date:</strong> {boat.date}<br/>
                                  <strong>Name:</strong> {boat.satellite_id.toUpperCase()}<br/>
                                  <strong>Beams:</strong> {boat.beam_ids.join(" / ")}<br/>
                                  <strong>Avg Mbps FW:</strong> {boat.satellite_id === "KVHTS" && boat.average_fwd || "N/A"}<br/>
                                  <strong>Avg Mbps RT:</strong> {boat.satellite_id === "KVHTS" && boat.average_rnt || "N/A"}<br/>
                                  <strong>Asked Mbps:</strong> {boat.needed || "N/A"}
                                </LeafletTooltip>
                              }
                            </Marker>
                  })
                }
                </LayerGroup>
              </LayersControl.Overlay>
            </LayersControl>
          </Map>
        </div>
        <div style={{width: "30%"}}>
          <div
            style={{
              margin: "20px",
            }}
          >
            <MapControls map={map} />

            <AnimationControls
              onPause={() => setIsRunning(false)}
              isRunning={isRunning}
              speed={speedBoat}
              onSpeedChange={evt => setSpeedBoat(Number(evt.target.value))}
              onPlay={() => setIsRunning(true)}
              onStop={stopAnimation}
              onSliderChange={(evt, value) => setCurrentFrameIndex(value)}
              firstFrameIndex={firstFrameIndex}
              currentFrameIndex={currentFrameIndex}
              lastFrameIndex={boats[0].positions.length - 1}
              sliderLabelFormat={value => boats[0].positions[value].date + " - " + value}
            />

            {/* <div style={{display: currentFrameIndex !== firstFrameIndex ? "block" : "none"}} >
              <Line
                ref={chartRef1}
                options={chartOptions}
                data={chartData1}
              />
              <Line
                ref={chartRef2}
                options={chartOptions}
                data={chartData2}
              />
              <Line
                ref={chartRef3}
                options={chartOptions}
                data={chartData3}
              />
            </div> */}

            <section 
              // style={{display: currentFrameIndex === firstFrameIndex ? "block" : "none"}}
            >
              <FilterForm onExport={exportCsv} onSubmit={showOnMap} data={data}/>
              <Backdrop className={classes.backdrop} open={openLoadingBar}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaritimeScreen;
