import Slider from '@material-ui/core/Slider';
import React from "react";
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { MdPlayArrow, MdPause, MdStop } from "react-icons/md";
import { IconButton, Tooltip, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

const useStyles = makeStyles (theme =>({
  timeline: {
    backgroundColor: "#3498db20",
    border: "1px solid #3498db30",
    marginBottom: "1em",
    padding: "10px 1em",
    borderRadius: "5px",
  },
  timelineSlider: {
    margin: "0 1em",
    display: "block",
    width: "auto"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: "auto"
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <CustomTooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </CustomTooltip>
  );
}

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main
  },
}))(Tooltip);


export default function AnimationControls(props) {
  const classes = useStyles();

  return (
    <div className={classes.timeline} >
      <Slider
        className={classes.timelineSlider}
        onChange={props.onSliderChange}
        value={props.currentFrameIndex}
        valueLabelFormat={props.sliderLabelFormat}
        defaultValue={0}
        aria-labelledby="discrete-slider-small-steps"
        step={1}
        marks
        min={props.firstFrameIndex}
        max={props.lastFrameIndex}
        valueLabelDisplay={props.currentFrameIndex > props.firstFrameIndex ? "on" : "off"}
        ValueLabelComponent={ValueLabelComponent}
      />

      <div style={{display:"flex"}}>
        <IconButton color="primary" onClick={props.onStop} aria-label="stop"><MdStop /></IconButton>

        { props.isRunning
          ? <IconButton color="primary" onClick={props.onPause} aria-label="pause"><MdPause /></IconButton>
          : <IconButton color="primary" onClick={props.onPlay} aria-label="play"><MdPlayArrow /></IconButton>
        }

        <FormControl variant="outlined" className={classes.formControl} size="small">
          <InputLabel id="demo-simple-select-outlined-label">Vitesse</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={props.speed}
            onChange={props.onSpeedChange}
            label="Vitesse"
          >
            <MenuItem value={200}><small>X</small>1</MenuItem>
            <MenuItem value={100}><small>X</small>2</MenuItem>
            <MenuItem value={50}><small>X</small>3</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  )
}
