import KVHTSJson from "./KVHTS.json";
import E10B_GSM_Json from "./E10B_GSM.json";
import E10B_HTS_Json from "./E10B_HTS.json";

const KVHTS = KVHTSJson.Folder.Document
  .map(beam => {
    return {
      id: beam.name.replace(/Spot_|.kml/g, ""),
      name: "KVHTS - Beam " + beam.name.replace(/Spot_|.kml/g, ""),
      coordinates: beam.Placemark[0].LineString.coordinates.split(" ").map(c => c.split(","))
    }
  });

const E10B_GSM = E10B_GSM_Json.features
  .map(beam => {
    return {
      id: "gsm_" + beam.properties.name,
      name: "E10B GSM - Beam " + beam.properties.name,
      coordinates: beam.geometry.coordinates[0]
    }
  });

  const E10B_HTS = E10B_HTS_Json.features
  .map(beam => {
    return {
      id: "hts_" + beam.properties.name,
      name: "E10B HTS - Beam " + beam.properties.name,
      coordinates: beam.geometry.coordinates[0]
    }
  });

export default {
    KVHTS,
    E10B_GSM,
    E10B_HTS
};
