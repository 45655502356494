import L from "leaflet";
import AIDA from "../images/companies/AIDA.png";
import CCL from "../images/companies/CCL.png";
import COSTA from "../images/companies/COSTA.png";
import CUK from "../images/companies/CUK.png";
import CUNARD from "../images/companies/CUNARD.png";
import SBN from "../images/companies/SBN.png";
import HAL from "../images/companies/HAL.png";

const boatColors = [
  "120,120,120",
  "00, 125, 186",
  "13, 23, 78",
  "217, 102, 44",
  "182, 56, 102",
  "231, 76, 60",
  "44, 182, 159",
  "142, 68, 173",
  "52, 73, 94",
  "138, 00, 00",
  "76, 176, 240",
  "184, 233, 148",
];

function companyLogo(c) {
  switch (c) {
    case "AIDA":
      return AIDA;
    case "CCL":
      return CCL;
    case "COSTA":
      return COSTA;
    case "CUK":
      return CUK;
    case "CUNARD":
      return CUNARD;
    case "SBN":
      return SBN;
    case "HAL":
      return HAL;
    default:
      return CCL;
  }
}

const backgroundColor = (color) => {
  return color === "none" ? "none" : `rgb(${boatColors[color]})`;
};

const boatIcon = (boatName, color = 0, company, value) => {
  color = Number(color);

  return new L.DivIcon({
    className: "boatMarker",
    html:`
      <div
        class="boatMarker-logo"
        style="background: #fff url('${companyLogo(company)}') center / cover"></div>
      <span
        class="boatMarker-value"
        style="display:${value ? "block" : "none"};background:${backgroundColor(color)}">${value}</span>
      <svg
        class="boatMarker-name" width="84" height="16" viewBox="0 0 84 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <text text-anchor="middle" fill="black" stroke="white" xml:space="preserve" style="white-space: pre;paint-order: stroke fill;" font-family="Helvetica Neue" font-size="11" font-weight="500" letter-spacing="0.02em"><tspan x="42" y="12.169">${boatName}</tspan></text>
      </svg>
    `,
  });
};

export default boatIcon;
