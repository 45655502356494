import React, { useState } from "react";
import { makeStyles, createTheme,  ThemeProvider} from "@material-ui/core/styles";
import Toggle from "react-toggle";
import { IoAirplaneSharp, IoBoatSharp } from "react-icons/io5";

import AeroScreen from "./Screens/AeroScreen";
import MaritimeScreen from "./Screens/Maritime/MaritimeScreen";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  toggleText: {
    width: "4em",
    marginLeft: "2em",
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#017EBE",
    },
    secondary: {
      main: "#33516D"
    },
  },
});

function App() {
  const [isAero, setIsAero] = useState(false);

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
    <div className="App" style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          marginRight: "4em",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img
            src={"/logo-eutelsat.png"}
            className="App-logo"
            alt="logo"
            style={{
              width: "10em",
              height: "auto",
              margin: "0 15px",
            }}
          />
        <div
          style={{
            display: "flex",
          }}
        >
          <label>
            <Toggle
              defaultChecked={isAero}
              className="space-switcher"
              onChange={() => {
                setIsAero(!isAero);
              }}
              icons={{
                checked: <IoAirplaneSharp size={20} />,
                unchecked: <IoBoatSharp size={20} />,
              }}
            />
          </label>
          <span className={classes.toggleText}>
            {isAero ? "Aero" : "Maritime"}
          </span>
        </div>
      </div>
      {isAero ? <AeroScreen /> : <MaritimeScreen />}
      <div style={{ display: "flex" }}></div>
    </div>
    </ThemeProvider>
  );
}

export default App;
