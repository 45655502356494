import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import AutocompleteAsync from "../components/AutoCompleteAsync";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#FDFDFD",
    border: "1px solid #EFEFEF",
    marginBottom: "1em",
    padding: "15px",
    borderRadius: "5px",
  },
  datetimeBloc: {
    display: "inline-flex",
  },
  datetimeLeft: {
    marginRight: "1em",
  },
  footer: {
    marginTop: "1em",
    display: "flex",
    fontSize: "14px",
    alignItems: "center",
    justifyContent: "space-around",
  },
  total: {
    padding: "8px",
  }
}));

export default function FilterForm (props) {
  const [fromDate, setFromDate] = useState(new Date("2019-04-22T00:00"));
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [fromTime, setFromTime] = useState(new Date("2019-04-22T00:00"));
  const [toDate, setToDate] = useState(new Date("2019-04-23T00:00"));
  const [toTime, setToTime] = useState(new Date("2019-04-23T00:00"));
  const [vesselName, setVesselName] = useState([]);
  const [vesselType, setVesselType] = useState([]);
  const [length, setLength] = useState([]);
  const [operator, setOperator] = useState([]);
  const [company, setCompany] = useState([]);
  const [dataRequest, setDataRequest] = React.useState("max");

  const classes = useStyles();

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function get_sum() {
    return props.data["other"] ? props.data["other"]["sum"] : 0;
  }

  function setTime(date, time) {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      0
    );
    return newDate;
  }

  function submitForm(event) {
    event.preventDefault();
    if (fromDateError === "" && toDateError === "") {
      props.onSubmit({
        dataRequest,
        vesselName,
        vesselType,
        length,
        operator,
        company,
        fromDateTime: setTime(fromDate, fromTime),
        toDateTime: setTime(toDate, toTime),
      });
    }
  }

  function exportCsv(event) {
    event.preventDefault();
    props.onExport({
      dataRequest,
      vesselName,
      vesselType,
      length,
      operator,
      company
    });
  }
  return (
    <div className={classes.container}>
      <AutocompleteAsync value={vesselName} setValue={setVesselName} url="api/maritime/vessel/name" label="Vessel name" classe={"item-right-block"} />
      <AutocompleteAsync value={vesselType} setValue={setVesselType} url="api/maritime/vessel/type" label="Vessel type" classe={"item-right-block"} />
      <AutocompleteAsync value={length} setValue={setLength} url="api/maritime/length" label="Length" classe={"item-right-block"} />
      <AutocompleteAsync value={operator} setValue={setOperator} url="api/maritime/operator" label="Operator" classe={"item-right-block"} />
      <AutocompleteAsync value={company} setValue={setCompany} url="api/maritime/company" label="Company" classe={"item-right-block"} />
      
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.datetimeBloc}>
          <div className={classes.datetimeLeft}>
            <KeyboardDatePicker
              margin="normal"
              variant="inline"
              id="date-picker-dialog"
              label="From"
              helperText={fromDateError}
              error={fromDateError !== ""}
              format="dd/MM/yyyy"
              value={fromDate}
              onChange={(date) => {
                setFromDateError("");
                if (!isValidDate(date)) {
                  setFromDateError(`Date is not valide`);
                }
                if (date > toDate) {
                  setFromDateError(`From-date cannot be greater than To-date`);
                }
                setFromDate(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
          <KeyboardTimePicker
            // disableToolbar
            margin="normal"
            variant="inline"
            ampm={false}
            id="starttime-picker"
            label="Time"
            value={fromTime}
            onChange={(date) => {
              setFromTime(date);
            }}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
          />
        </div>
        <div className={classes.datetimeBloc}>
          <div className={classes.datetimeLeft}>
            <KeyboardDatePicker
              // disableToolbar
              margin="normal"
              variant="inline"
              id="date-picker-dialog"
              label="To"
              format="dd/MM/yyyy"
              helperText={toDateError}
              error={toDateError !== ""}
              value={toDate}
              onChange={(date) => {
                setToDateError("");
                if (!isValidDate(date)) {
                  setToDateError(`Date is not valide`);
                }
                if (date < fromDate) {
                  setToDateError(`To-date cannot be less than From-date`);
                }
                setToDate(date);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
          <KeyboardTimePicker
            // disableToolbar
            margin="normal"
            ampm={false}
            variant="inline"
            id="endtime-picker"
            label="Time"
            value={toTime}
            onChange={(date) => {
              setToTime(date);
            }}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
          />
        </div>
      </MuiPickersUtilsProvider>
      <FormControl component="fieldset">
        <FormLabel component="legend"></FormLabel>
        <RadioGroup
          row
          value={dataRequest}
          onChange={(event) => {
            setDataRequest(event.target.value);
          }}
        >
          <FormControlLabel value="max" control={<Radio />} label="Max" />
          <FormControlLabel value="avg" control={<Radio />} label="Average" />
        </RadioGroup>
      </FormControl>
      <div className={classes.footer}>
        <div className={classes.total}>Total : {get_sum()} Gbps</div>

        <Button variant="outlined" onClick={submitForm}>
          Show on Map
        </Button>
        <Button variant="contained" onClick={exportCsv} color="primary">
          Export CSV
        </Button>
      </div>
    </div>
  );
}


