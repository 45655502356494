import React, { useState } from "react";
import { MdLocationSearching } from "react-icons/md";
import { IconButton, Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  controls: {
    display: "flex",
    backgroundColor: "#FDFDFD",
    border: "1px solid #EFEFEF",
    marginBottom: "1em",
    padding: "10px 1em",
    borderRadius: "5px",
  },
  posMapInput: {
    marginRight: "1em",
  },
}));

export default function MapControls(props){
  const classes = useStyles();

  const [zoomInput, setZoomInput] = useState(3);
  const [latInput, setLatInput] = useState(48.85);
  const [longInput, setLongInput] = useState(2.44);

  return (
    <div className={classes.controls}>
      <TextField
        id="standard-number"
        label="Zoom"
        type="number"
        className={classes.posMapInput}
        value={zoomInput}
        onChange={(event) => {
          setZoomInput(event.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="standard-number"
        label="Lat"
        type="number"
        value={latInput}
        className={classes.posMapInput}
        onChange={(event) => {
          setLatInput(event.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="standard-number"
        label="Long"
        type="number"
        value={longInput}
        className={classes.posMapInput}
        onChange={(event) => {
          setLongInput(event.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Tooltip title="Go">
        <IconButton
          onClick={() => {
            props.map.setView([latInput, longInput], zoomInput);
          }}
          aria-label="delete"
        >
          <MdLocationSearching />
        </IconButton>
      </Tooltip>
    </div>
  );
}
