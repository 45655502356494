import fascinosa from "./costa_fascinosa.json";
import fascinosa_fwd_rnt from "./costa_fascinosa_fwd_rnt.json";
console.log(fascinosa_fwd_rnt);

const boats= [
  fascinosa.slice(19000)
];

const needed = {
  "01": null,
  "02": 140,
  "03": 140,
  "04": 141,
  "05": 138,
  "06": 157,
  "07": 168,
  "08": 168,
  "09": 174,
  "10": 146,
  "11": 138,
  "12": 167
};

export default boats.map(boat => ({
  company: boat[0]["Brand"],
  boatName: boat[0]["Site Name"],
  positions: boat.map(position => {

    let satellite_id = null;
    if (position["KVHTS"]) {
      satellite_id = "KVHTS";
    }else if (position["E10B"] && position["E10B"].includes("hts")) {
      satellite_id = "E10B_HTS";
    }else if (position["E10B"] && position["E10B"].includes("gsm")) {
      satellite_id = "E10B_GSM";
    }

    let beam_ids = [];

    if(satellite_id === "KVHTS"){
      beam_ids = position["KVHTS"].split(";")
    }

    if(satellite_id === "E10B_HTS" || satellite_id === "E10B_GSM"){
      beam_ids = position["E10B"].split(";")
    }

    return {
      date: position["Date"],
      needed: needed[position["Date"].slice(3, 5)],
      average_fwd : satellite_id && fascinosa_fwd_rnt[satellite_id]["Average FWD"][position["Date"]],
      average_rnt : satellite_id && fascinosa_fwd_rnt[satellite_id]["Average RTN"][position["Date"]],
      satellite_id,
      beam_ids,
      lat: position.lat,
      lon: position.lng
    };
  }),
}));
